import { useState, useEffect, useRef } from 'react';



import DetailSource2_contents from './DetailSource2_contents'
import SelectColumnFilter from '../Table/SelectColumnFilter'

import { useParams } from 'react-router-dom';


export default function DetailSource1(props) {

  // useState
  const [issueList, setIssueList] = useState([{}]);
  const [issueDetail, setIssueDetail] = useState({});
  const columnsSourceList = [
    { Header: "概要"      , accessor: "pb_bSelectedOverview"
        , Filter: SelectColumnFilter, filter: 'equals' 
    },
    { Header: "pb_strH2Text"  , accessor: "pb_strH2Text" },

//    { Header: "ID"                 , accessor: "ID" },
    { Header: "INDEX"              , accessor: "INDEX" },
//    { Header: "pb_strHeader"              , accessor: "pb_strHeader" },
//    { Header: "段落"        , accessor: "pb_nParagraphIndex" },
//    { Header: "文 "         , accessor: "pb_nSentenceIndex" },

    { Header: "sentence_org"              , accessor: "sentence_org", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_org}} />   }, 
    // { Header: "sentence_edit_2"           , accessor: "sentence_edit_2", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_edit_2}} />   },
    { Header: "sentence_org_at_react"  , accessor: "sentence_org_at_react", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_org_at_react}} />   },

//    { Header: "sentence"                  , accessor: "sentence", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence}} />  }, 
//    { Header: "sentence_edit"             , accessor: "sentence_edit", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_edit}} />   },
//    { Header: "sentence_1"                , accessor: "sentence_1", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_1}} />   },
//    { Header: "strSentence_edit_by_text"  , accessor: "strSentence_edit_by_text", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.strSentence_edit_by_text}} />   },

//    { Header: "features"                  , accessor: "features" },
//    { Header: "features_1"                , accessor: "features_1" },

    // { Header: "pb_strH1Text"  , accessor: "pb_strH1Text" },
    // { Header: "pb_strH2Text"  , accessor: "pb_strH2Text" },
    // { Header: "pb_strH3Text"  , accessor: "pb_strH3Text" },
    // { Header: "pb_strH4Text"  , accessor: "pb_strH4Text" },
  ];
  const [sourceList, setSourceList] = useState([{}]);

  // useRef
  const refSourceByDynamoDB = useRef();

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log("useEffect@DetailSource1");
    //console.log("props.issueList@DetailSource1" + props.issueList);
    //console.log("props.issueDetail@DetailSource1" + props.issueDetail);
    console.log("props.sourceList@DetailSource1:" + props.sourceList.length);
    const p = issueDetail;
    //refreshDetail(params.id);
    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail);
    setSourceList(props.sourceList);

  }, [issueList, issueDetail, sourceList]); // end useEffect

  const refreshDetail = (id) => {
    //setIssueDetailByApi(id);
    refSourceByDynamoDB.current?.refDoInit(id);
  };

  return (
    <div>
          <DetailSource2_contents
                  columnsSourceList={columnsSourceList}
                  issueList={issueList} 
                  issueDetail={issueDetail} 
                  sourceList={sourceList}
           ></DetailSource2_contents>
    </div>
  );
};
