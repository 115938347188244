import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import ParentList from './components/detail/ParentList';
import DetailMain from './components/detail/DetailMain';
import Detail from './components/detail/Detail';
import DetailSource from './components/detail/DetailSource';
import DetailSource1 from './components/detail/DetailSource1';
import Album from './components/Album';
import Album_edit from './components/detail/Album_edit';

function App() {
  return (
    <BrowserRouter>
      <nav>
        <ul>
          <li>
            <Link to='/'>Back To Top</Link>
          </li>
          <li>
            <Link to='/parentList'>ParentList</Link>
          </li>
          <li>
            <Link to='/album'>Album layout</Link>
          </li>
          <li>
            <Link to='/album_edit'>Album edit layout</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route exact path='/parentList' element={<ParentList />} />
        <Route path='/detailMain/:id' element={<DetailMain />} />
        <Route path='/detail/:id' element={<Detail />} />
        <Route path='/detailSource/:id' element={<DetailSource />} />
        <Route path='/detailSource1/:id' element={<DetailSource1 />} />
        <Route path='/album' element={<Album />} />
        <Route path='/album_edit' element={<Album_edit />} />
        <Route path='/' element={<ParentList />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
