import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


import ApiList from '../api/ApiList'
import ApiIns from '../api/ApiIns'
import ApiUpd from '../api/ApiUpd'
import ApiDel from '../api/ApiDel'
import ReactTable from '../Table/ReactTable'
import ReactTableExampleSubComponents from '../Table/example/SubComponents/ReactTableExampleSubComponents'


import Button from '@material-ui/core/Button';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ParentList() {

  // useState
  const [list, setList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "りんご",
      price: "120円",
      stock: "130"
    },
    {
      ID: '2',
      SUBJECT: 'SUBJECT2',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "バナナ",
      price: "100円",
      stock: "200"
    },
    {
      ID: '3',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    }
  ]);

  const [issue, setIssue] = useState({BODY:'body<br/>body'});

  const columns = [
    { Header: "ID", accessor: "ID" },
    { Header: "SUBJECT", accessor: "[link]", 
      Cell: ({ row }) => {
        return <Button variant='outlined' color='secondary'
                            onClick={() => refreshIssue(row.original.ID)}>{row.original.SUBJECT}</Button>
      }

    },
    // { Header: "BODY", accessor: "BODY" },
    { Header: "商品", accessor: "product" },
    { Header: "値段", accessor: "price" },
    { Header: "在庫", accessor: "stock" }
  ];
  
  const refreshIssue = (id) => {
    console.log('list@@@@@@@@@@@@@@@@@@@@ ' + id);
    console.log(list);
    const result = list.filter(data => data.ID == id);
    if (result.length > 0) {
      console.log('result[0] ');
      console.log(result[0]);
      // TODO newValue?
      const newValue = result[0];
      setIssue(newValue);
    }
  };

  // TODO
  const onChangeaaa = (event) => {
    console.log("onChange " + event.target.value);
    issue.SUBJECT = event.target.value;
    const newValue = issue;
    setIssue(newValue);
    console.log(issue.SUBJECT);

  };

  // TODO
  const onChange_SOURCE = (event) => {
    console.log("event"); 
    console.log(event);
    console.log("event.target"); 
    console.log(event.target);
    console.log("onChange_SOURCE " + event.target.innerText);
    issue.SOURCE = event.target.innerText;
    const newValue = issue;
    setIssue(newValue);
    console.log(issue.SOURCE);
  };

  // TODO
  const onChange_URL = (event) => {
    issue.URL = event.target.innerText;
    const newValue = issue;
    setIssue(newValue);
  };

  // TODO
  const onChange_OVERVIEW = (event) => {
    issue.OVERVIEW = event.target.innerText;
    const newValue = issue;
    setIssue(newValue);
  };

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {
    console.log('list');
    console.log(list);

    console.log('setList111');
    console.log(setList);

  }, []); // end useEffect

  return (
    <div>
        <Grid container>
          <Grid item xs={4}>

            {/* TODO デバッグ表示？？ */}
            ParentList
            {list[0].product}
            ParentList
          </Grid>

          {/* リストの表示 */}
          <Grid item xs={12}>
            <div className={classes.issue_list} >
              <ReactTable columns={columns} data={list} />
            </div>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
              <Item>xs=6 md=8</Item>
            </Grid>
            <Grid item xs={6} md={4}>
              <Item>xs=6 md=4</Item>
            </Grid>
            <Grid item xs={6} md={4}>
              <Item>xs=6 md=4</Item>
            </Grid>
            <Grid item xs={6} md={8}>
              <Item>xs=6 md=8</Item>
            </Grid>
          </Grid>



          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト取得 */}
                <ApiList
                  setList={setList}
                  setIssue={setIssue}
                  ></ApiList>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト登録 */}
                <ApiIns
                  issue={issue}></ApiIns>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト更新 */}
                <ApiUpd
                  issue={issue}></ApiUpd>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト削除 */}
                <ApiDel
                  issue={issue}></ApiDel>
              </Item>
            </Grid>
          </Grid>








          <Grid item xs={12}>
            ID
            <textarea	className={classes.form_term_list}  value={issue.ID} />
          </Grid>
          <Grid item xs={12}>
            SUBJECT
            <input type="text" 
              defaultValue={issue.SUBJECT} 
              onChange={onChangeaaa} 
              className={classes.form_term_list}  
              contentEditable="true"
              />
                
            {/* <div className={classes.form_overview} 
                dangerouslySetInnerHTML={{ __html: issue.SUBJECT }} 
                onChange={onChangeaaa}
                contentEditable="true"/> */}
          </Grid>
          <Grid item xs={12}>
            URL
            <div className={classes.form_url} dangerouslySetInnerHTML={{ __html: issue.URL }} 
              onBlur={onChange_URL} 
              contentEditable="true"/>
          </Grid>
          <Grid item xs={12}>
            TERM_LIST
            <textarea	className={classes.form_term_list}  value={issue.TERM_LIST} />
            <textarea	className={classes.form_term_list}  value={issue.TERM_LIST_1} />
            <textarea	className={classes.form_term_list}  value={issue.TERM_LIST_2} />
            <textarea	className={classes.form_term_list}  value={issue.TERM_LIST_3} />
          </Grid>
          <Grid item xs={12}>
            SOURCE
            <div className={classes.form_source} dangerouslySetInnerHTML={{ __html: issue.SOURCE }}
              onBlur={onChange_SOURCE} 
              contentEditable="true"
            ></div>
          </Grid>
          <Grid item xs={12}>
            OVERVIEW
            <div className={classes.form_overview}  
              onBlur={onChange_OVERVIEW} 
              contentEditable="true">{issue.OVERVIEW}</div>
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW }} />
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW_1 }} />
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW_2 }} />
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW_3 }} />
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW_4 }} />
            <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issue.OVERVIEW_5 }} />
          </Grid>
          <Grid item xs={12}>
            YOYAKU
            <div className={classes.form_yoyaku} dangerouslySetInnerHTML={{ __html: issue.YOYAKU }}></div>
          </Grid>
          {/* グリッドのサンプル */}
          <Grid item xs={12}>
            <ReactTableExampleSubComponents></ReactTableExampleSubComponents>
          </Grid>
        </Grid>
    </div>
  );
};
