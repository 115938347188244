import axios from 'axios';
import Button from '@material-ui/core/Button';
import { CONSTANT } from '../Config';

export default function ApiDel(props) {

  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const urlDel = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/del';
  // const urlDel = '/issue/del';

  const del = (url) => {
    console.log('props.issue');
    console.log(props.issue);

    const data = props.issue;
    axios.post(url, data)
      .then(res => {
        console.log('成功')
      })
      .catch((err) => {
        console.log(err) // 失敗
      });
  }

  return (
    <div>
      {/* <div>
        ApiDel
        {props.issue.ID}-
        {props.issue.SUBJECT}
      </div> */}
      <Button variant='outlined' color='secondary'
        onClick={() => del(urlDel)}>削除</Button>
    </div>
  );
};
