import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import axios from 'axios';


import { CONSTANT } from '../Config';
import HeaderDetail from '../HeaderDetail'
import List from './List'
import ApiIns from '../api/ApiIns'
import ApiUpd from '../api/ApiUpd'
import ApiDel from '../api/ApiDel'
import ApiOpenai from '../api/ApiOpenai'


import { Link, useMatch, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'
import memo from './memo.css'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Detail(props) {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  const [issueDetail, setIssueDetail] = useState(
      {
        ID: '1',
        TERM_LIST:'', 
        YOYAKU:'YOYAKU<br/>YOYAKU'});

  const [sourceList, setSourceList] = useState([{}]);

  const [SOURCE, setSOURCE] = useState({BODY:'body<br/>body'});

  const refreshIssue = (id) => {
    console.log('issueList@@@@@@@@@@@@@@@@@@@@ ' + id);
    console.log(issueList);
    const result = issueList.filter(data => data.ID == id);
    if (result.length > 0) {
      console.log('result[0] ');
      console.log(result[0]);
      // TODO newValue?
      const newValue = result[0];
      setIssueDetail(newValue);
    }
  };

  // TODO
  const onChangeaaa = (event) => {
    console.log("onChange " + event.target.value);
    issueDetail.SUBJECT = event.target.value;
    const newValue = issueDetail;
    setIssueDetail(newValue);
    console.log(issueDetail.SUBJECT);

  };

  // TODO
  const onChange_SOURCE = (event) => {
    console.log("event"); 
    console.log(event);
    console.log("event.target"); 
    console.log(event.target);
    console.log("onChange_SOURCE " + event.target.innerText);
    issueDetail.SOURCE = event.target.innerText;
    const newValue = issueDetail;
    setIssueDetail(newValue);
    console.log(issueDetail.SOURCE);
  };

  // TODO
  const onChange_URL = (event) => {
    issueDetail.URL = event.target.innerText;
    const newValue = issueDetail;
    setIssueDetail(newValue);
  };

  // TODO
  const onChange_OVERVIEW = (event) => {
    issueDetail.OVERVIEW = event.target.innerText;
    const newValue = issueDetail;
    setIssueDetail(newValue);
  };

  // TODO
  const onChange_OVERVIEW_5 = (event) => {
    issueDetail.OVERVIEW_5 = event.target.innerText;
    const newValue = issueDetail;
    setIssueDetail(newValue);
  };

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log(params);
    console.log(params.id);

    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail)
    setSourceList(props.sourceList);

    // SOURCEの設定
    setSource();
    
  }, [issueList, issueDetail, sourceList]); // end useEffect

  const refreshDetail = (id) => {
    setIssueDetailByApi(id);
  };

  const urlDetail = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/detail/';
  // ApiListとの変更点
  // ・パラメータを追加
  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const setIssueDetailByApi = (id) => {
    console.log('setIssueDetail');
    axios.get(urlDetail + id)
      .then(res => {
        const resData = res.data;
        console.log("resData");
        console.log(resData);
        if (resData.length > 0) {
          setIssueDetail(resData[0]);
        }
      });
  };

  const setSource = () => {
    console.log('setSource');
    const SOURCE = sourceList.map(data => {
      //console.log("map " + data.sentence_org)
      return data.sentence_org
    }).reduce((a, b) => 
    {
      //console.log("reduce " + a + "<BR/>" + b)
      return a + "<BR/>" + b
    });

    
    //console.log("SOURCE")
    //console.log(SOURCE)
    setSOURCE(SOURCE);
  };

  return (
    <div>
        Detail
        <Grid container>


          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト登録 */}
                <ApiIns
                  issue={issueDetail}></ApiIns>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト更新 */}
                <ApiUpd
                  issue={issueDetail}></ApiUpd>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：リスト削除 */}
                <ApiDel
                  issue={issueDetail}></ApiDel>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item>
                {/* API：openai */}
                <ApiOpenai
                  issue={issueDetail}></ApiOpenai>
              </Item>
            </Grid>
          </Grid>

          {/* リストの表示 */}
          <List issueList = {issueList} 
                refreshDetail = {refreshDetail}></List>

          <Grid item xs={12}>
            ID
            <textarea	className={classes.form_id}  value={issueDetail.ID} />
          </Grid>
          <Grid item xs={12}>
            SUBJECT
            <input type="text" 
              defaultValue={issueDetail.SUBJECT} 
              onChange={onChangeaaa} 
              className={classes.form_subject}  
              contentEditable="true"
              />
                
            {/* <div className={classes.form_overview} 
                dangerouslySetInnerHTML={{ __html: issueDetail.SUBJECT }} 
                onChange={onChangeaaa}
                contentEditable="true"/> */}
          </Grid>
          <Grid item xs={12}>
            URL
            <div className={classes.form_url} dangerouslySetInnerHTML={{ __html: issueDetail.URL }} 
              onBlur={onChange_URL} 
              contentEditable="true"/>
          </Grid>
          <Grid item xs={12}>
            TERM_LIST
            <textarea	className={classes.form_term_list}  value={issueDetail.TERM_LIST} />
            TERM_LIST_1
            <textarea	className={classes.form_term_list}  value={issueDetail.TERM_LIST_1} />
            TERM_LIST_2
            <textarea	className={classes.form_term_list}  value={issueDetail.TERM_LIST_2} />
            TERM_LIST_3
            <textarea	className={classes.form_term_list}  value={issueDetail.TERM_LIST_3} />
          </Grid>
          <Grid item xs={12}>
            OVERVIEW
            <div className={classes.form_overview}  
              onBlur={onChange_OVERVIEW} 
              contentEditable="true">{issueDetail.OVERVIEW}</div>
          </Grid>
          <Grid item xs={12}>
            OVERVIEW_5
            <div className={classes.form_overview}  
              onBlur={onChange_OVERVIEW_5} 
              contentEditable="true">{issueDetail.OVERVIEW_5}</div>
          </Grid>


          {/* 要約 */}
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Item>
              SOURCE
              <div className={classes.form_source} dangerouslySetInnerHTML={{ __html: SOURCE }}
                onBlur={onChange_SOURCE} 
                contentEditable="true"
              ></div>
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item>
              YOYAKU
              <div className={classes.form_yoyaku} dangerouslySetInnerHTML={{ __html: issueDetail.YOYAKU }}></div>
              </Item>
            </Grid>
          </Grid>
        </Grid>
    </div>
  );
};
