import { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import ReactTable from '../Table/ReactTable'

import { useParams } from 'react-router-dom';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Term(props) {

  // useState
  const [termList, setTermList] = useState([{}]);
  const termColumns = [
    { Header: "TERM", accessor: "TERM" },
  ];

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {
    console.log("issueDetail = " + props.issueDetail);
    if (props.TERM_LIST) {
      const termList = props.TERM_LIST.split('\t').map(token => ({TERM: token}));
      //console.log(termList);
      setTermList(termList);
    }
  }, [props.TERM_LIST]); // end useEffect


  return (
    <div>

        {/* <Grid item xs={12}>
          <textarea	className={classes.form_term_list}  value={props.TERM_LIST} />
        </Grid> */}

        <Grid container>

          {/* リストの表示 */}
          <Grid item xs={12}>
            <div className={classes.term_list} >
              <ReactTable columns={termColumns} data={termList} />
            </div>
          </Grid>

        </Grid>
    </div>
  );
};
