import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import Button from '@mui/material/Button';

import axios from 'axios';

import { useParams } from 'react-router-dom';

import { CONSTANT } from '../Config';
import Overview from './Overview';
import Detail from './Detail';
import DetailSource  from './DetailSource';
import DetailSource1 from './DetailSource1';
import DetailSource1_d3_for_term from './DetailSource1_d3_for_term';
import DetailSource2 from './DetailSource2';
import ReactTableExample from '../example/react/ReactTableExample';
import ExampleD3 from '../example/react/ExampleD3';
import Yoyaku from './Yoyaku';


// CSS
import classes from './test.module.css'


export default function DetailMain() {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  // useState
  const [issueDetail, setIssueDetail] = useState({TERM_LIST:'', YOYAKU:'YOYAKU<br/>YOYAKU'});

  // useState
  const [sourceList, setSourceList] = useState([{}]);

  // 20221005 以前は個別にタブのメタ情報を定義していたが、数が増えてきたので、連想オブジェクトに変更した
  // // useState
  // const [showOverview         , setShowOverview     ] = useState([false]);
  // const [showDetail           , setShowDetail       ] = useState([false]);
  // const [showDetailSource     , setShowDetailSource ] = useState([false]);
  // const [showDetailSource1    , setShowDetailSource1] = useState([false]);
  // const [showDetailSource1_d3_for_term    , setShowDetailSource1_d3_for_term] = useState([false]);
  // const [showDetailSource2    , setShowDetailSource2] = useState([false]);
  // const [showReactTableExample, setShowReactTableExample] = useState([false]);
  // const [showExampleD3        , setShowExampleD3    ] = useState([false]);
  // const [showYoyaku           , setShowYoyaku       ] = useState([false]);

  // // useState
  // const [colorOverview          , setColorOverview          ] = useState("primary")
  // const [colorDetail            , setColorDetail            ] = useState("secondary")
  // const [colorDetailSource      , setColorDetailSource      ] = useState("secondary")
  // const [colorDetailSource1     , setColorDetailSource1     ] = useState("secondary")
  // const [colorDetailSource1_d3_for_term     , setColorDetailSource1_d3_for_term     ] = useState("secondary")
  // const [colorDetailSource2     , setColorDetailSource2     ] = useState("secondary")
  // const [colorYoyaku            , setColorYoyaku            ] = useState("secondary")
  // const [colorReactTableExample , setColorReactTableExample ] = useState("secondary")
  // const [colorExampleD3         , setColorExampleD3         ] = useState("secondary")

  // タブのメタ情報
  const [tabMetaInfo, setTabMetaInfo] = useState({
    Overview                   : {show:true, color:"primary"},
    Detail                     : {show:false, color:"secondary"},
    DetailSource               : {show:false, color:"secondary"},
    DetailSource1              : {show:false, color:"secondary"},
    DetailSource1_d3_for_term  : {show:false, color:"secondary"},
    DetailSource2              : {show:false, color:"secondary"},
    Yoyaku                     : {show:false, color:"secondary"},
    ReactTableExample          : {show:false, color:"secondary"},
    ExampleD3                  : {show:false, color:"secondary"},
  });

    // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log("useEffect@DetailMain");

    setIssueListByApi();
    setIssueDetailByApi(params.id);
    setSourceByDynamoDBApi(params.id);

  }, []); // end useEffect

  // API：リスト取得
  const urlList = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/list';
  // ApiListとの変更点
  // ・パラメータを追加
  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const setIssueListByApi = () => {
    console.log('refreshIssueList');
    axios.get(urlList)
      .then(res => {
        const resData = res.data;
        if (true) {
          // デバッグログ
          console.log("resData");
          console.log(resData);
          const p = resData.map(data => {
            console.log(data.ID + " : " + data.SUBJECT);
          })
        }
        // レスポンスをプロパティに設定
        setIssueList(resData);
      });
  };

  // API：詳細取得
  const urlDetail = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/detail/';
  // ApiListとの変更点
  // ・パラメータを追加
  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const setIssueDetailByApi = (id) => {
    console.log('setIssueDetail');
    axios.get(urlDetail + id)
      .then(res => {
        const resData = res.data;
        console.log("resData");
        console.log(resData);
        if (resData.length > 0) {
          setIssueDetail(resData[0]);
        }
      });
  };

  // API：DynamoDB取得
  const url_dynamodb_list = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/dynamodb/list';
  const setSourceByDynamoDBApi = (ID) => {
    console.log('setSourceByDynamoDBApi');
    axios.get(url_dynamodb_list, {params: {ID: ID}})
      .then(res => {
        const resData = res.data;
        //console.log("resData");
        //console.log(resData);
        const SOURCE = resData.map(data => {
          //console.log("map " + data.sentence_org)
          return data.sentence_org
        }).reduce((a, b) => 
        {
          //console.log("reduce " + a + "<BR/>" + b)
          return a + "<BR/>" + b
        });

        
        const resData_edit = resData.map(data => {
          let sentence_edit_2_at_react = (data.pb_bSelectedOverview == "true")? data.sentence_edit_2 : "";
          let sentence_org_at_react    = (data.pb_bSelectedOverview == "true")? data.sentence_org : "";

          data["sentence_edit_2_at_react"] = sentence_edit_2_at_react;
          data["sentence_org_at_react"]    = sentence_org_at_react;
          return data;
        });

        //console.log("SOURCE")
        //console.log(SOURCE)
        //props.setSOURCE(SOURCE);

        // setSourceList(resData);
        setSourceList(resData_edit);
      });
  };

  // タブの表示・非表示を制御する
  const hideComponent = (name) => {
    // 20221005 苦労した。。
    // useState()? or useEffect()? 恐るべし。。（便利だけど、Javascriptに精通していないと意図した動きが実現できない。。）
    // 階層のあるオブジェクトをuseState()で使用すると、値を変更するだけだと変更を検知さないので、画面がリフレッシュされない。。
    // useState()で宣言したオブジェクトを新規に作成してsetしないといけないので注意が必要。。
    // この方式だと、render()は走るが、useEffect()は走らないので都合がよいのだが、、仕組みを理解していないので、もやもやする。。
    // ==> 前回の連想オブジェクトではなく個別に変数を複数定義した実装方式だと、数が多すぎて、無限ループになってたのかな？ｗｗ
    let newObj = [];
    for(const key of Object.keys(tabMetaInfo)) {
      newObj[key] = {show: false, color:"secondary"};
      if (key == name) {
        newObj[key] = {show: true, color:"primary"};
      }
    }
    setTabMetaInfo(newObj);
  }

  return (

    <div>
    DetailMain
    <Button variant='contained' color={tabMetaInfo.Overview.color} onClick={() => hideComponent("Overview")}>概要</Button>
    <Button variant='contained' color={tabMetaInfo.Yoyaku.color}   onClick={() => hideComponent("Yoyaku")}  >要約</Button>
    <Button variant='contained' color={tabMetaInfo.Detail.color}   onClick={() => hideComponent("Detail")}  >ソースと要約と編集(Detail)</Button>

   <Grid container spacing={2}>
    <Grid item xs={6} md={4}>
      <Button variant='contained' color={tabMetaInfo.DetailSource.color}
                onClick={() => hideComponent("DetailSource")}
                                                                            >Dynamoソース(DetailSource)</Button>
      <Button variant='contained' color={tabMetaInfo.DetailSource1.color}
                onClick={() => hideComponent("DetailSource1")}
                                                                            >Dynamoソース1とフィルタと単語(DetailSource1)</Button>
      <Button variant='contained' color={tabMetaInfo.DetailSource1_d3_for_term.color}
                onClick={() => hideComponent("DetailSource1_d3_for_term")}
                                                                            >Dynamoソース1とフィルタと単語(DetailSource1_d3_for_term)</Button>
      <Button variant='contained' color={tabMetaInfo.DetailSource2.color}
                onClick={() => hideComponent("DetailSource2")}
                                                                            >Dynamoソース1とフィルタと単語(DetailSource2 org)</Button>
    </Grid>
   </Grid>
    <Button variant='contained' color={tabMetaInfo.ReactTableExample.color}
                onClick={() => hideComponent("ReactTableExample")}
                                                                            >ReactTableExample</Button>
    <Button variant='contained' color={tabMetaInfo.ExampleD3.color}
                onClick={() => hideComponent("ExampleD3")}
                                                                            >Example D3</Button>

      {tabMetaInfo.Overview.show && <Overview
                              issueList={issueList} 
                              issueDetail={issueDetail} />}
      {tabMetaInfo.Detail.show && <Detail
                              issueList={issueList} 
                              issueDetail={issueDetail} 
                              sourceList={sourceList}
                              />}

      {tabMetaInfo.DetailSource.show && <DetailSource 
                              issueList={issueList} 
                              issueDetail={issueDetail} 
                              sourceList={sourceList}
                               />}

      {tabMetaInfo.DetailSource1.show && <DetailSource1 
                              issueList={issueList} 
                              issueDetail={issueDetail} 
                              sourceList={sourceList}
                              />}

      {tabMetaInfo.DetailSource1_d3_for_term.show && <DetailSource1_d3_for_term 
                              issueList={issueList} 
                              issueDetail={issueDetail} 
                              sourceList={sourceList}
                              />}

      {tabMetaInfo.DetailSource2.show && <DetailSource2
                              issueList={issueList} 
                              issueDetail={issueDetail} 
                              sourceList={sourceList}
                              />}
      {tabMetaInfo.Yoyaku.show && <Yoyaku 
                              issueList={issueList} 
                              issueDetail={issueDetail} />}
      {tabMetaInfo.ReactTableExample.show && <ReactTableExample />}
      {tabMetaInfo.ExampleD3.show && <ExampleD3 />}


    </div>

 );
}