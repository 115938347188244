import axios from 'axios';
import Button from '@material-ui/core/Button';
import { CONSTANT } from '../Config';

export default function ApiIns(props) {

  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const urlIns = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/ins';

  const ins1 = (url) => {

    const data = { SUBJECT: 'SUBJECT',
                   BODY: 'BODYあああ' };
    axios.post(url, data)
      .then(res => {
        console.log('成功')
      })
      .catch((err) => {
        console.log(err) // 失敗
      });
  }

  const ins = (url) => {
    console.log('props.issue');
    console.log(props.issue);

    const data = props.issue;
    axios.post(url, data)
      .then(res => {
        console.log('成功')
      })
      .catch((err) => {
        console.log(err) // 失敗
      });
  }

  const ins3 = (url) => {


    axios.post(url, {
//#                 ID                   : null
                  SUBJECT              : null
                , TYPE                 : null
                , STATUS               : null
                , SEARCH_TITLE         : null
                , SUB_COUNT            : null
                , DATE                 : null
                , SOURCE               : null
                , OVERVIEW             : null
                , YOYAKU               : null
                , OVERVIEW_1           : null
                , OVERVIEW_2           : null
                , OVERVIEW_3           : null
                , OVERVIEW_4           : null
                , OVERVIEW_5           : null
                , OVERVIEW_6           : null
                , OVERVIEW_7           : null
                , OVERVIEW_8           : null
                , OVERVIEW_9           : null
                , OVERVIEW_10          : null
                , OVERVIEW_11          : null
                , OVERVIEW_12          : null
                , OVERVIEW_13          : null
                , OVERVIEW_14          : null
                , OVERVIEW_15          : null
                , YOYAKU_1             : null
                , YOYAKU_2             : null
                , YOYAKU_3             : null
                , YOYAKU_4             : null
                , YOYAKU_5             : null
                , YOYAKU_6             : null
                , YOYAKU_7             : null
                , YOYAKU_8             : null
                , YOYAKU_9             : null
                , YOYAKU_10            : null
                , YOYAKU_11            : null
                , YOYAKU_12            : null
                , YOYAKU_13            : null
                , YOYAKU_14            : null
                , YOYAKU_15            : null
                , SOURCE_1             : null
                , SOURCE_2             : null
                , SOURCE_3             : null
                , SOURCE_4             : null
                , SOURCE_5             : null
                , SOURCE_6             : null
                , SOURCE_7             : null
//#                ,'SOURCE_5            : null
//#                ,'SOURCE_6            : null
//#                ,'SOURCE_7            : null

                , SOURCE_8             : null
                , SOURCE_9             : null
                , SOURCE_10            : null
                , SOURCE_11            : null
                , SOURCE_12            : null
                , SOURCE_13            : null
                , SOURCE_14            : null
                , SOURCE_15            : null
                , BODY_1               : null
                , BODY_2               : null
                , BODY_3               : null
                , BODY_4               : null
                , BODY_5               : null
                , BODY_6               : null
                , BODY_7               : null
                , BODY_8               : null
                , BODY_9               : null
                , BODY_10              : null
                , BODY_11              : null
                , BODY_12              : null
                , BODY_13              : null
                , BODY_14              : null
                , BODY_15              : null

                , TERM_LIST            : null
                , TERM_LIST_1          : null
                , TERM_LIST_2          : null
                , TERM_LIST_3          : null
                , TERM_LIST_4          : null
                , TERM_LIST_5          : null
                , TERM_LIST_6          : null
                , TERM_LIST_7          : null
                , TERM_LIST_8          : null
                , TERM_LIST_9          : null
                , TERM_LIST_10         : null
                , TERM_LIST_11         : null
                , TERM_LIST_12         : null
                , TERM_LIST_13         : null
                , TERM_LIST_14         : null
                , TERM_LIST_15         : null

                , CATEGORY_1           : null
                , CATEGORY_2           : null
                , CATEGORY_3           : null
                , CATEGORY_4           : null
                , CATEGORY_5           : null
                , BODY                 : null
                , URL                  : null
                , URL_IMAGE            : null
                , AMAZON_URL           : null
                , LINK_RELATED         : null
                , LINK_RELATED_1       : null
                , LINK_RELATED_2       : null
                , LINK_RELATED_3       : null
                , LINK_RELATED_4       : null
                , LINK_RELATED_5       : null
                , LINK_RELATED_6       : null
                , LINK_RELATED_7       : null
                , LINK_RELATED_8       : null
                , LINK_RELATED_9       : null
                , LINK_RELATED_10      : null
                //, UID                 : null
                , UID                  : null
                // , DATE_UPD_FLAG      : null

                , NAME                 : null
                , NAME1                : null
                , NAME2                : null
                , NAME3                : null
                , NAME_KANA            : null
                , NAME1_KANA           : null
                , NAME2_KANA           : null
                , NAME3_KANA           : null
                , EMAIL1               : null

                , TEL                  : null
                , TEL1                 : null
                , TEL2                 : null
                , TEL3                 : null

                , ZIP                  : null
 
                , ADDRESS              : null
                , ADDRESS1             : null
                , ADDRESS2             : null
                , ADDRESS3             : null

                , ADDRESS_PREFECTURE   : null
                , ADDRESS1_PREFECTURE  : null
                , ADDRESS2_PREFECTURE  : null
                , ADDRESS3_PREFECTURE  : null

                , NICK_NAME            : null
//                , BIRTH_DAY           : null
//                , DEATH_DAY           : null
                , HOME_VILLAGE         : null
                , BODY_HEIGHT          : null
                , BODY_WEIGHT          : null

                , TWITTER              : null
                , TWITTER_1            : null
                , TWITTER_2            : null
                , TWITTER_3            : null
                , TWITTER_4            : null
                , TWITTER_5            : null


      // SUBJECT: null
      // , TYPE                      : null
      // , DATE                      : null
      // , STATUS                    : null
      // , SEARCH_TITLE              : null
      // , SUB_COUNT                 : null
      // , CATEGORY_1                : null
      // , BODY                      : null
      // , NAME                      : null
      // , UID                       : null
      // , OVERVIEW                  : null
      // , SOURCE                    : null
      // , YOYAKU                    : null
      // , TERM_LIST                 : null
      // , URL                       : null
      // , URL_IMAGE                 : null
      // , AMAZON_URL                : null
      // , LINK_RELATED              : null
      // , RECORD_FLAG               : 0   
      // , NAME1                     : null
      // , NAME2                     : null
      // , NAME3                     : null
      // , ZIP                       : null
      // , ZIP1                      : null
      // , ZIP2                      : null
      // , ZIP3                      : null
      // , ADDRESS_PREFECTURE        : null
      // , ADDRESS1_PREFECTURE       : null
      // , ADDRESS2_PREFECTURE       : null
      // , ADDRESS3_PREFECTURE       : null
      // , ADDRESS                   : null
      // , ADDRESS1                  : null
      // , ADDRESS2                  : null
      // , ADDRESS3                  : null
      // , EMAIL                     : null
      // , EMAIL1                    : null
      // , EMAIL2                    : null
      // , EMAIL3                    : null
      // , TEL                       : null
      // , TEL1                      : null
      // , TEL2                      : null
      // , TEL3                      : null
      // , PRD_USER_ID               : null
      // , PRD_ORDER_PRD_ID          : null
      // , PRD_PRICE                 : null
      // , PRD_COUNT                 : null
      // , PRD_PRICE_DSP             : null
      // , NAME_KANA                 : null
      // , NAME1_KANA                : null
      // , NAME2_KANA                : null
      // , NAME3_KANA                : null
      // , PRD_DELIVERY_PRICE        : 0   
      // , PRD_PRICE_WARIBIKI        : null
      // , PRD_PRICE_WARIBIKI_DSP    : null
      // , PRD_PRICE_REFERENCE       : null
      // , PRD_PRICE_REFERENCE_DSP   : null
      // , PRD_DELIVERY_PRICE_EXPLAIN: null
      // , CATEGORY_2                : null
      // , CATEGORY_3                : null
      // , CATEGORY_4                : null
      // , CATEGORY_5                : null
      // , LINK_RELATED_1            : null
      // , LINK_RELATED_2            : null
      // , LINK_RELATED_3            : null
      // , OVERVIEW_1                : null
      // , OVERVIEW_2                : null
      // , OVERVIEW_3                : null
      // , OVERVIEW_4                : null
      // , OVERVIEW_5                : null
      // , NICK_NAME                 : null
      // , BIRTH_DAY                 : null
      // , HOME_VILLAGE              : null
      // , BODY_HEIGHT               : null
      // , BODY_WEIGHT               : null
      // , DEATH_DAY                 : null
      // , TWITTER                   : null
      // , TWITTER_1                 : null
      // , TWITTER_2                 : null
      // , TWITTER_3                 : null
      // , TWITTER_4                 : null
      // , TWITTER_5                 : null
      // , TERM_LIST_1               : null
      // , TERM_LIST_2               : null
      // , TERM_LIST_3               : null
      // , TERM_LIST_4               : null
      // , TERM_LIST_5               : null
      // , OVERVIEW_6                : null
      // , OVERVIEW_7                : null
      // , OVERVIEW_8                : null
      // , OVERVIEW_9                : null
      // , OVERVIEW_10               : null
      // , OVERVIEW_11               : null
      // , OVERVIEW_12               : null
      // , OVERVIEW_13               : null
      // , OVERVIEW_14               : null
      // , OVERVIEW_15               : null
      // , SOURCE_1                  : null
      // , SOURCE_2                  : null
      // , SOURCE_3                  : null
      // , SOURCE_4                  : null
      // , SOURCE_5                  : null
      // , SOURCE_6                  : null
      // , SOURCE_7                  : null
      // , SOURCE_8                  : null
      // , SOURCE_9                  : null
      // , SOURCE_10                 : null
      // , SOURCE_11                 : null
      // , SOURCE_12                 : null
      // , SOURCE_13                 : null
      // , SOURCE_14                 : null
      // , SOURCE_15                 : null
      // , YOYAKU_1                  : null
      // , YOYAKU_2                  : null
      // , YOYAKU_3                  : null
      // , YOYAKU_4                  : null
      // , YOYAKU_5                  : null
      // , YOYAKU_6                  : null
      // , YOYAKU_7                  : null
      // , YOYAKU_8                  : null
      // , YOYAKU_9                  : null
      // , YOYAKU_10                 : null
      // , YOYAKU_11                 : null
      // , YOYAKU_12                 : null
      // , YOYAKU_13                 : null
      // , YOYAKU_14                 : null
      // , YOYAKU_15                 : null
      // , BODY_1                    : null
      // , BODY_2                    : null
      // , BODY_3                    : null
      // , BODY_4                    : null
      // , BODY_5                    : null
      // , BODY_6                    : null
      // , BODY_7                    : null
      // , BODY_8                    : null
      // , BODY_9                    : null
      // , BODY_10                   : null
      // , BODY_11                   : null
      // , BODY_12                   : null
      // , BODY_13                   : null
      // , BODY_14                   : null
      // , BODY_15                   : null
      // , LINK_RELATED_4            : null
      // , LINK_RELATED_5            : null
      // , LINK_RELATED_6            : null
      // , LINK_RELATED_7            : null
      // , LINK_RELATED_8            : null
      // , LINK_RELATED_9            : null
      // , LINK_RELATED_10           : null
      // , TERM_LIST_6               : null
      // , TERM_LIST_7               : null
      // , TERM_LIST_8               : null
      // , TERM_LIST_9               : null
      // , TERM_LIST_10              : null
      // , TERM_LIST_11              : null
      // , TERM_LIST_12              : null
      // , TERM_LIST_13              : null
      // , TERM_LIST_14              : null
      // , TERM_LIST_15              : null
      // , DATE_1                    : null
      // , DATE_2                    : null
      // , DATE_3                    : null
      // , DATE_4                    : null
      // , DATE_5                    : null
      // , INT_1                     : null
      // , INT_2                     : null
      // , INT_3                     : null
      // , INT_4                     : null
      // , INT_5                     : null
      // , DOUBLE_1                  : null
      // , DOUBLE_2                  : null
      // , DOUBLE_3                  : null
      // , DOUBLE_4                  : null
      // , DOUBLE_5                  : null
    })
      .then(res => {
        const resData = res.data;
        console.log(resData);
      });

  };

  return (
    <div>
      {/* <div>
        ApiIns
        {props.issue.ID}-
        {props.issue.SUBJECT}
      </div> */}
      <Button variant='outlined' color='secondary'
        onClick={() => ins(urlIns)}>ins</Button>
    </div>
  );
};
