import { useState, useEffect, useRef } from 'react';
import { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';



import HeaderDetail from '../HeaderDetail'
import ReactTableWithFilter from '../Table/ReactTableWithFilter'
import SelectColumnFilter from '../Table/SelectColumnFilter'
import List from './List'
import Term from './Term'
import D3BubbleChart from './D3BubbleChart'

import { useParams } from 'react-router-dom';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

export default function DetailSource1(props) {

  // useState
  const [issueList  , setIssueList  ] = useState([{}]);
  const [issueDetail, setIssueDetail] = useState({});
  const [sourceList , setSourceList ] = useState([{}]);

  // react-tableのフィルタ条件用
  const [initialState, setInitialState] = useState({
          filters: [
              {
                  id: 'sentence_org',
                  value: '',
              },
          ],
      });

  // useRef
  const refSourceByDynamoDB = useRef();

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log("useEffect@DetailSource1");
    //console.log("props.issueList@DetailSource1" + props.issueList);
    //console.log("props.issueDetail@DetailSource1" + props.issueDetail);
    console.log("props.sourceList@DetailSource1:" + props.sourceList.length);
    const p = issueDetail;
    //refreshDetail(params.id);
    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail);
    setSourceList(props.sourceList);
  }, [issueList, issueDetail, sourceList]); // end useEffect

  const refreshDetail = (id) => {
    //setIssueDetailByApi(id);
    refSourceByDynamoDB.current?.refDoInit(id);
  };

  const ppp = () => {
    highlightcheck();
  };

  const popopopo = (e) => {
    console.log("popopopo");
    console.log(e.target.__data__.groupid);
    console.log(e);

    // react-tableのフィルタ条件用のキーワードを更新
    setInitialState({
          filters: [
              {
                  id: 'sentence_org',
                  value: e.target.__data__.groupid,
              },
          ],
      });

    // ハイライト
    highlightcheck(e.target.__data__.groupid);

  };

  // ▼①元のHTMLソースを保持しておく変数
  var backupOriginal = "";
  // ▼②文字列を検索してハイライト用要素を加える処理
  function replacer( str, word , att  ) {
      var SearchString = '(' + word + ')';
      var RegularExp = new RegExp( SearchString, "g" );
      var ReplaceString = '<span class="' + att + '">$1</span>';


      //var ReplaceString = '<B>"' + att + '">$1</B>';

      var ResString = str.replace( RegularExp , ReplaceString );
      return ResString;
  }
  // ▼③ハイライトを加える処理
  function addhighlight(target_term) {

      // バックアップしてから、ハイライト処理を実行する
      backup();

      console.log("addhighlight() " + target_term);

      const sourceList_edit = sourceList.map(source => {

          if (source.sentence_org.indexOf("米英") != -1) {
            var p = 0;
          }

          /*
          var TERM_LIST = issueDetail.TERM_LIST.split('\t');
          for(var i = 0; i < TERM_LIST.length; i++) {


            if (i > 8) {
                break;
            }
            var term = TERM_LIST[i];
            console.log(term);
            source.sentence_org = replacer( source.sentence_org, term, "mark1" );
            source.sentence_org_at_react = replacer( source.sentence_org_at_react, term, "mark1" );

          }
          */

          source.sentence_org = replacer( source.sentence_org, target_term, "mark1" );
          source.sentence_org_at_react = replacer( source.sentence_org_at_react, target_term, "mark1" );

          return source;
      });

      setSourceList(sourceList_edit);

      // backupOriginal = document.getElementById("targetspace").innerHTML;
      // var forShow = backupOriginal;
      // forShow = replacer( forShow, "ハイライト", "mark1" );
      // forShow = replacer( forShow, "文章", "mark2" );
      // forShow = replacer( forShow, "表示", "mark3" );
      // document.getElementById("targetspace").innerHTML = forShow;
  }

  // バックアップ
  function backup() {
      const sourceList_edit = sourceList.map(source => {
          source["sentence_org_backup"         ] = source.sentence_org;
          source["sentence_org_at_react_backup"] = source.sentence_org_at_react;
          return source;
      });
      setSourceList(sourceList_edit);
  }

  function restore() {
      const sourceList_edit = sourceList.map(source => {
        source.sentence_org          = source.sentence_org_backup;
        source.sentence_org_at_react = source.sentence_org_at_react_backup;
          return source;
      });
      setSourceList(sourceList_edit);
  }

  // ▼④ハイライトを消す処理
  function clearhighlight() {
      const sourceList_edit = sourceList.map(source => {

          var TERM_LIST = issueDetail.TERM_LIST.split('\t');
          for(var i = 0; i < TERM_LIST.length; i++) {
            if (i > 8) {
                break;
            }
            var term = TERM_LIST[i];
            console.log(term);
            source.sentence_org = replacer( source.sentence_org, term, "mark1" );
            source.sentence_org_at_react = replacer( source.sentence_org_at_react, term, "mark1" );
          }

          return source;
      });

      setSourceList(sourceList_edit);
//      document.getElementById("targetspace").innerHTML = backupOriginal;  // バックアップから書き戻す
//      backupOriginal = "";    // バックアップを消す
  }
  // ▼⑤ハイライトを加えるか消すかを判断
  function highlightcheck(target_term) {
      if( backupOriginal.length == 0 ) {
          // 何もバックアップされていなければ（未ハイライトなので）ハイライトを加える
          addhighlight(target_term);
      }
      else {
          // 何かバックアップされていれば（ハイライト済みなので）ハイライトを消す
          clearhighlight();
      }
  }

  // 子コンポーネントに親の関数を渡して実行させる
  //（子のバブルチャート内でクリックされたら、親のハイライト処理を実行したい）
  // React hook dependent functions as props
  // https://stackoverflow.com/questions/55169549/react-hook-dependent-functions-as-props
  // TODO useCallback を理解して整理しないと。。
  const handleClick = useCallback(
    (e) => popopopo(e),
    [issueDetail]
  );
  
  return (
    <div>

      DetailSource1
      <Grid container>

          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          {/* リストの表示 */}
          <List issueList = {issueList} 
                refreshDetail = {refreshDetail}></List>

          {/* 単語一覧の表示 */}
          <Term TERM_LIST = {issueDetail.TERM_LIST}></Term>

        TERM_LIST_2<div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issueDetail.TERM_LIST_2 }} />
        TERM_LIST_3<div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issueDetail.TERM_LIST_3 }} />
        TERM_LIST_4<div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: issueDetail.TERM_LIST_4 }} />

          {/* 単語一覧のD3のバブルチャートで表示 */}
          <D3BubbleChart TERM_LIST_2 = {issueDetail.TERM_LIST_2} popopopo = {handleClick}></D3BubbleChart>

    <Button variant='contained' onClick={() => ppp()}         >ハイライト</Button>
    <Button variant='contained' onClick={() => restore()}         >オフ</Button>

          <Grid container>
            {/* ソースと要約の比較表示 */}
            <Grid item xs={12}>
              <div className={classes.react_table_source_and_yoyaku} >
                <ReactTableWithFilter columns={props.columnsSourceList} data={sourceList} initialState={initialState} />
              </div>
            </Grid>
          </Grid>
      </Grid>
    </div>
  );
};
