import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



import HeaderDetail from '../HeaderDetail'
import List from './List'
import ReactTable from '../Table/ReactTable'


import { Link, useMatch, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export default function DetailSource(props) {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  const [issueDetail, setIssueDetail] = useState({TERM_LIST:'', YOYAKU:'YOYAKU<br/>YOYAKU'});
  
  const [sourceList, setSourceList] = useState([{}]);
  const columnsSourceList = [
    // { Header: "ID", accessor: "ID" },
    { Header: "sentence_org", accessor: "[link]", 
      Cell: ({ row }) => {
        return <Button variant='outlined' color='secondary'
                            onClick={() => refreshIssue(1)}>{row.original.sentence_org}</Button>
      }
    }, 
//    { Header: "sentence_edit_2_at_react", accessor: "sentence_edit_2_at_react" },
    { Header: "sentence_org_at_react", accessor: "sentence_org_at_react" },
  ];

  const refreshIssue = (id) => {
    console.log('issueList@@@@@@@@@@@@@@@@@@@@ ' + id);
    console.log(issueList);
    const result = issueList.filter(data => data.ID == id);
    if (result.length > 0) {
      console.log('result[0] ');
      console.log(result[0]);
      // TODO newValue?
      const newValue = result[0];
      setIssueDetail(newValue);
    }
  };

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log(params);
    console.log(params.id);

    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail)
    setSourceList(props.sourceList);

  }, [issueList, issueDetail, sourceList]); // end useEffect

  const refreshDetail = (id) => {
    //setIssueDetailByApi(id);
  };

  return (
    <div>
    
        DetailSource
        <Grid container>

          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          {/* リストの表示 */}
          <List issueList = {issueList} 
                refreshDetail = {refreshDetail}></List>

          {/* ソースの表示 */}
          <Grid item xs={12}>
            <div >
              <ReactTable columns={columnsSourceList} data={sourceList} />
            </div>
          </Grid>
        </Grid>
    </div>
  );
};
