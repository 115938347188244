import axios from 'axios';
import Button from '@material-ui/core/Button';
import { CONSTANT } from '../Config';

export default function ApiUpd(props) {

  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const urlOpenai = 'https://api.openai.com/v1/';

  const upd = (url) => {

    // TODO
    const MODEL = 'gpt-3.5-turbo';
    const API_KEY='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';

    console.log('props.issue');
    console.log(props.issue);
    console.log(props.issue.SOURCE);

    let message = '以下を要約してください。 ' + props.issue.SOURCE;
    // const response = await axios.post( `${ API_URL }chat/completions`, {
    const response = axios.post( `${ url }chat/completions`, {
      // モデル ID の指定
      model: MODEL,
      // 質問内容
      messages: [
        {
          'role': 'user',
          'content': message,
        }
      ],
    }, {
      // 送信する HTTP ヘッダー(認証情報)
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ API_KEY }`
      }
    }).then ( res => {
      console.log( res.data );
      console.log( res.data.choices[0].message.content );

      // TODO 2023/07/02
      // ・urlを絶対パスにする必要があるｗｗ
      // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
      const urlUpd = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/upd';
      // ローカル環境の場合は、プロキシを使用する？
      // const urlUpd = '/issue/upd';

      // OpenAIの結果を、OVERVIEW_5に保存する
      const data = props.issue;
      data.OVERVIEW_5 = res.data.choices[0].message.content;
      axios.post(urlUpd, data)
        .then(res => {
          console.log('成功')
        })
        .catch((err) => {
          console.log(err) // 失敗
        });
  
    }).catch((err) => {
      console.log(err) // 失敗
    });

  }

  return (
    <div>
      {/* <div>
        ApiUpd
        {props.issue.ID}-
        {props.issue.SUBJECT}
      </div> */}
      <Button variant='outlined' color='secondary'
        onClick={() => upd(urlOpenai)}>Openai</Button>
    </div>
  );
};
