import { useState, useEffect, } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import ApiList from '../api/ApiList'
import ReactTable from '../Table/ReactTable'


import { Link, useMatch, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function List(props) {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  const columns = [
    { Header: "ID", accessor: "ID" },
    { Header: "SUBJECT", accessor: "[link]", 
      Cell: ({ row }) => {
        return <Button variant='outlined' color='secondary'
                            onClick={() => props.refreshDetail(row.original.ID)}>{row.original.SUBJECT}</Button>
      }
    },
  ];

  // TODO temp 削除したい。ApiListを改修しないといけない
  const [issue, setIssue] = useState({BODY:'body<br/>body'});

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {
    console.log("useEffect@List");
    console.log(params);
    console.log(params.id);

    setIssueList(props.issueList);

  }, [issueList]); // end useEffect

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Item>
            {/* API：リスト取得 */}
            <ApiList
              setList={setIssueList}
              setIssue={setIssue}
              ></ApiList>
          </Item>
        </Grid>
      </Grid>


      {/* リストの表示 */}
      <Grid item xs={12}>
        <div className={classes.issue_list} >
          <ReactTable columns={columns} data={issueList} />
        </div>
      </Grid>
    </Grid>
  );
};
