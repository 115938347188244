import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import axios from 'axios';


import { CONSTANT } from '../Config';
import HeaderDetail from '../HeaderDetail'
import List from './List'


import { Link, useMatch, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'
import memo from './memo.css'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Detail(props) {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  const [issueDetail, setIssueDetail] = useState(
      {
        ID: '1',
        TERM_LIST:'', 
        YOYAKU:'YOYAKU<br/>YOYAKU'});

  const [SOURCE, setSOURCE] = useState({BODY:'body<br/>body'});

  const refreshIssue = (id) => {
    console.log('issueList@@@@@@@@@@@@@@@@@@@@ ' + id);
    console.log(issueList);
    const result = issueList.filter(data => data.ID == id);
    if (result.length > 0) {
      console.log('result[0] ');
      console.log(result[0]);
      // TODO newValue?
      const newValue = result[0];
      setIssueDetail(newValue);
    }
  };

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log(params);
    console.log(params.id);

    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail)

  }, [issueList, issueDetail]); // end useEffect

  const refreshDetail = (id) => {
    setIssueDetailByApi(id);
  };

  const urlDetail = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/detail/';
  // ApiListとの変更点
  // ・パラメータを追加
  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const setIssueDetailByApi = (id) => {
    console.log('setIssueDetail');
    axios.get(urlDetail + id)
      .then(res => {
        const resData = res.data;
        console.log("resData");
        console.log(resData);
        if (resData.length > 0) {
          setIssueDetail(resData[0]);
        }
      });
  };

  return (
    <div>
        Yoyaku
        <Grid container>


          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          {/* リストの表示 */}
          <List issueList = {issueList} 
                refreshDetail = {refreshDetail}></List>

          <Grid item xs={12}>
            YOYAKU
            <div className={classes.form_yoyaku} dangerouslySetInnerHTML={{ __html: issueDetail.YOYAKU }}></div>
          </Grid>
        </Grid>
    </div>
  );
};
