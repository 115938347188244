import { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



import HeaderDetail from '../HeaderDetail'
import ReactTableWithFilter from '../Table/ReactTableWithFilter'
import SelectColumnFilter from '../Table/SelectColumnFilter'
import List from './List'
import Term from './Term'
import D3BubbleChart from './D3BubbleChart'

import { useParams } from 'react-router-dom';

// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

export default function DetailSource1(props) {

  // useState
  const [issueList, setIssueList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
    },
  ]);

  const [issueDetail, setIssueDetail] = useState({TERM_LIST:'', YOYAKU:'YOYAKU<br/>YOYAKU'});
  
  const columnsSourceList = [

    { Header: "概要"      , accessor: "pb_bSelectedOverview"
        , Filter: SelectColumnFilter, filter: 'equals' 
    },
    { Header: "pb_strH2Text"  , accessor: "pb_strH2Text" },

//    { Header: "ID"                 , accessor: "ID" },
//    { Header: "INDEX"              , accessor: "INDEX" },
//    { Header: "pb_strHeader"              , accessor: "pb_strHeader" },
//    { Header: "段落"        , accessor: "pb_nParagraphIndex" },
//    { Header: "文 "         , accessor: "pb_nSentenceIndex" },

//    { Header: "sentence_org"              , accessor: "sentence_org", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_org}} />   }, 
    { Header: "sentence_edit_2"           , accessor: "sentence_edit_2", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_edit_2}} />   },
    { Header: "sentence_edit_2_at_react"  , accessor: "sentence_edit_2_at_react", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_edit_2_at_react}} />   },

//    { Header: "sentence"                  , accessor: "sentence", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence}} />  }, 
//    { Header: "sentence_edit"             , accessor: "sentence_edit", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_edit}} />   },
//    { Header: "sentence_1"                , accessor: "sentence_1", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.sentence_1}} />   },
//    { Header: "strSentence_edit_by_text"  , accessor: "strSentence_edit_by_text", Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.strSentence_edit_by_text}} />   },

//    { Header: "features"                  , accessor: "features" },
//    { Header: "features_1"                , accessor: "features_1" },

    // { Header: "pb_strH1Text"  , accessor: "pb_strH1Text" },
    // { Header: "pb_strH2Text"  , accessor: "pb_strH2Text" },
    // { Header: "pb_strH3Text"  , accessor: "pb_strH3Text" },
    // { Header: "pb_strH4Text"  , accessor: "pb_strH4Text" },
  ];
  const [sourceList, setSourceList] = useState([
    {
// 初期表示以外は、APIのレスポンスで上書きされるので、細かく設定する必要は基本的にはない。。
// ただし、APIレスポンスがどのようなものか把握するために、以下のソースは残しておきましょう。。
//       pb_bSelectedOverview      : 'pb_bSelectedOverview',
//       pb_strH2Text           : 'pb_strH2Text',

//       //      ID                        : 'ID',
// //      INDEX                     : 'INDEX',

// //      pb_strHeader              : 'pb_strHeader',
// //      pb_nParagraphIndex        : 'pb_nParagraphIndex',
// //      pb_nSentenceIndex         : 'pb_nSentenceIndex',

// //      sentence_org              : 'sentence_org',
//       sentence_edit_2           : 'sentence_edit_2',
//       sentence_edit_2_at_react    : 'sentence_edit_2_at_react',

// //      sentence                  : 'sentence',
// //      sentence_edit             : 'sentence_edit',
// //      sentence_1                : 'sentence_1',
// //      strSentence_edit_by_text  : 'strSentence_edit_by_text',

// //      features                  : 'features',
// //      features_1                : 'features_1',

//       // pb_strH1Text           : 'pb_strH1Text',
//       // pb_strH2Text           : 'pb_strH2Text',
//       // pb_strH3Text           : 'pb_strH3Text',
//       // pb_strH4Text           : 'pb_strH4Text',
    },
  ]);

  // useRef
  const refSourceByDynamoDB = useRef();

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    console.log("useEffect@DetailSource1");
    //console.log("props.issueList@DetailSource1" + props.issueList);
    //console.log("props.issueDetail@DetailSource1" + props.issueDetail);
    console.log("props.sourceList@DetailSource1:" + props.sourceList.length);
    const p = issueDetail;
    //refreshDetail(params.id);
    setIssueList(props.issueList);
    setIssueDetail(props.issueDetail);
    setSourceList(props.sourceList);

  }, [issueList, issueDetail, sourceList]); // end useEffect

  const refreshDetail = (id) => {
    //setIssueDetailByApi(id);
    refSourceByDynamoDB.current?.refDoInit(id);
  };

  return (
    <div>

        DetailSource1
        <Grid container>

          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          {/* リストの表示 */}
          <List issueList = {issueList} 
                refreshDetail = {refreshDetail}></List>

          {/* 単語一覧の表示 */}
          <Term TERM_LIST = {issueDetail.TERM_LIST}></Term>

          {/* 単語一覧のD3のバブルチャートで表示 */}
          <D3BubbleChart TERM_LIST_2 = {issueDetail.TERM_LIST_2}></D3BubbleChart>

          <Grid container>
            {/* ソースと要約の比較表示 */}
            <Grid item xs={12}>
              <div className={classes.react_table_source_and_yoyaku} >
                <ReactTableWithFilter columns={columnsSourceList} data={sourceList} />
              </div>
            </Grid>
          </Grid>
        </Grid>
    </div>
  );
};
