import { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import { Link, useParams } from 'react-router-dom';

export default function HeaderDetail(props) {

  // useState
  const [ID, setID] = useState([0]);

  // useParams:パラメーターの取得
  const params = useParams();

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    setID(params.id)

  }, []); // end useEffect

  return (

    <Grid container>
      <Grid item xs={4} md={4}>
          <Link to={`/detail/${ID}`}>Detail</Link>
      </Grid>
      <Grid item xs={4} md={4}>
          <Link to={`/DetailSource/${ID}`}>DetailSource</Link>
      </Grid>
      <Grid item xs={4} md={4}>
          <Link to={`/DetailSource1/${ID}`}>DetailSource1</Link>
      </Grid>
    </Grid>

  );
};
