import { useState, useEffect, useMemo } from 'react';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MtLink from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Link
} from 'react-router-dom';

import ApiList from '../api/ApiList'

// CSS
import classes from './test.module.css'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright ﾂｩ '}
      <MtLink color="inherit" href="https://material-ui.com/">
        Your Website
      </MtLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const cards = [1, 2, 3, 4, 5];

const theme = createTheme();

export default function Album_edit() {

  // useState
  const [list, setList] = useState([
    {
      ID: '1',
      SUBJECT: 'SUBJECT1',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "りんご",
      price: "120円",
      stock: "130"
    },
    {
      ID: '2',
      SUBJECT: 'SUBJECT2',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "バナナ",
      price: "100円",
      stock: "200"
    },
    {
      ID: '3',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '4',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '5',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '6',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '7',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '8',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '9',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '10',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '11',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '12',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    },
    {
      ID: '13',
      SUBJECT: 'SUBJECT3',
      OVERVIEW: 'OVERVIEW',
      BODY: 'BODY',
      product: "メロン",
      price: "3400円",
      stock: "2"
    }

  ]);

  const [issue, setIssue] = useState({BODY:'body<br/>body'});

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Album edit layout
          </Typography>
        </Toolbar>
      </AppBar>
      <main>

                <ApiList
                  defaultGet={true}
                  setList={setList}
                  setIssue={setIssue}
                  ></ApiList>

        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Album edit layout
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Something short and leading about the collection below窶琶ts contents,
              the creator, etc. Make it short and sweet, but not too short so folks
              don&apos;t simply skip over it entirely.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {list.map((card) => (
              /*<Grid item key={card} xs={12} sm={6} md={12}>*/
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image="https://source.unsplash.com/random"
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.SUBJECT}
                    </Typography>
                    <Typography>
                      {/* {card.OVERVIEW} */}

                        <div className={classes.form_overview} dangerouslySetInnerHTML={{ __html: card.OVERVIEW }} />

                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View@</Button>
                    <Button size="small">Edit@</Button>

            <Link to={`/DetailMain/${card.ID}`}>DetailMain</Link>
            <Link to={`/detail/${card.ID}`}>Detail</Link>
            <Link to={`/DetailSource/${card.ID}`}>1</Link>
            <Link to={`/DetailSource1/${card.ID}`}>2</Link>





                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}