import { useEffect } from 'react';

import * as d3 from "d3";


// CSS
// 【React 入門】スタイル（CSS）の当て方のまとめ
// https://harad-hakusyo.com/web/react/style/
import classes from './test.module.css'

export default function D3BubbleChart(props) {

  // useState
//  const [TERM_LIST_2, setTERM_LIST_2] = useState([]);
  
  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {

    if (props.TERM_LIST_2) {
        doD3BubbleChart(props.TERM_LIST_2);
    }

  }, [props.TERM_LIST_2]); // end useEffect


  const doD3BubbleChart = (TERM_LIST_2) => {

    console.log(TERM_LIST_2);
    const termLineList = TERM_LIST_2.split('\n').map(line => line);
    console.log("@@@ termLineList");
    console.log(termLineList);
    console.log("@@@ termLineList[2]");
    console.log(termLineList[2]);
    const termTokenList = termLineList[2].split('\t').map(token => (token.split(":")));
    console.log("@@@ termTokenList");
    console.log(termTokenList);
    // const p = termTokenList.map((list, index) => index + "," + list[1] + "," + list[0]);
    // バブルチャートの設定
    // バブルの個数：最初から15番目までを対象とする
    // バブルの中のテキスト：「単語名(件数)」のフォーマットとする
    let ppp = termTokenList.slice(0, 14)
                       .map((list, index) => ({
                                                   id     : index, 
                                                   groupid: list[0], 
                                                   size   : list[1]
                                               }));
    console.log("ppp");
    console.log(ppp);





    // （参照元）https://bl.ocks.org/officeofjane/a70f4b44013d06b9c0a973f163d8ab7a
    // （流用元）ExampleD3
    function bubbleChart() {
      // const width = 940;
      // const height = 500;
      // const width = 1800;
      // const height = 1200;
      const width = 600;
      const height = 400;
    
      // location to centre the bubbles
      const centre = { x: width/2, y: height/2 };
    
      // strength to apply to the position forces
      const forceStrength = 0.03;
    
      // these will be set in createNodes and chart functions
      let svg = null;
      let bubbles = null;
      let labels = null;
      let nodes = [];
    
      // charge is dependent on size of the bubble, so bigger towards the middle
      function charge(d) {
        return Math.pow(d.radius, 2.0) * 0.01
      }
    
      // create a force simulation and add forces to it
      const simulation = d3.forceSimulation()
        .force('charge', d3.forceManyBody().strength(charge))
        // .force('center', d3.forceCenter(centre.x, centre.y))
        .force('x', d3.forceX().strength(forceStrength).x(centre.x))
        .force('y', d3.forceY().strength(forceStrength).y(centre.y))
        .force('collision', d3.forceCollide().radius(d => d.radius + 1));
    
      // force simulation starts up automatically, which we don't want as there aren't any nodes yet
      simulation.stop();
    
      // set up colour scale
      const fillColour = d3.scaleOrdinal()
        .domain(["1", "2", "3", "5", "99"])
        .range(["#0074D9", "#7FDBFF", "#39CCCC", "#3D9970", "#AAAAAA"]);
    
      // data manipulation function takes raw data from csv and converts it into an array of node objects
      // each node will store data and visualisation values to draw a bubble
      // rawData is expected to be an array of data objects, read in d3.csv
      // function returns the new node array, with a node for each element in the rawData input
      //
      // データ操作関数は、csvから生データを受け取り、ノードオブジェクトの配列に変換します。
      // 各ノードには、バブルを描画するためのデータとビジュアライゼーションの値が格納される
      // rawData は d3.csv で読み込まれたデータオブジェクトの配列であることが期待されます。
      // 関数は、rawData の各要素に対応するノードを持つ、新しいノード配列を返します。
      function createNodes(rawData) {
        // use max size in the data as the max in the scale's domain
        // note we have to ensure that size is a number
        const maxSize = d3.max(rawData, d => +d.size);
    
        // size bubbles based on area
        const radiusScale = d3.scaleSqrt()
          .domain([0, maxSize])
//          .range([0, 80])
          .range([0, 40])  // バブルのサイズみたい
    
        // use map() to convert raw data into node data
        const myNodes = rawData.map(d => ({
          ...d,
          radius: radiusScale(+d.size),
          size: +d.size,
          x: Math.random() * 900,
          y: Math.random() * 800
        }))
    
        return myNodes;
      }
    
      // main entry point to bubble chart, returned by parent closure
      // prepares rawData for visualisation and adds an svg element to the provided selector and starts the visualisation process
      let chart = function chart(selector, rawData) {
        // convert raw data into nodes data
        nodes = createNodes(rawData);
    
        // create svg element inside provided selector
        svg = d3.select(selector)
          .append('svg')
          .attr('width', width)
          .attr('height', height)
    
        // bind nodes data to circle elements
        const elements = svg.selectAll('.bubble')
          .data(nodes, d => d.id)
          .enter()
          .append('g')

        bubbles = elements
          .append('circle')
          .classed('bubble', true)
          .attr('r', d => d.radius)
          .attr('fill', d => fillColour(d.size))


          // クリック時のアクション
          // よく理解していないが、下記を参考にした。。
          // d3.js: click event in a bubble/scatter chart
          // https://stackoverflow.com/questions/18342416/d3-js-click-event-in-a-bubble-scatter-chart
          .on("click", function(d){
                    //console.log("aaaa");
                    //console.log(d);
                    console.log(d.target.__data__.groupid);
                    props.popopopo(d);
                })
    
        // labels
        labels = elements
          .append('text')
          .attr('dy', '.3em')
          .style('text-anchor', 'middle')
          .style('font-size', 10)
          // 編集した！！
          .text(d => `${d.groupid} (${d.size})`)
    
        // set simulation's nodes to our newly created nodes array
        // simulation starts running automatically once nodes are set
        simulation.nodes(nodes)
          .on('tick', ticked)
          .restart();
      }
    
      // callback function called after every tick of the force simulation
      // here we do the actual repositioning of the circles based on current x and y value of their bound node data
      // x and y values are modified by the force simulation
      function ticked() {
        bubbles
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
    
        labels
          .attr('x', d => d.x)
          .attr('y', d => d.y)
      }
    
      // return chart function from closure
      return chart;
    }
    
    // new bubble chart instance
    let myBubbleChart = bubbleChart();
    
    // function called once promise is resolved and data is loaded from csv
    // calls bubble chart function to display inside #vis div
    function display(data) {
      console.log("@@@@@@@@@@@@@display");
      console.log(data);
      myBubbleChart('#vis', data);
    }
    
    // load data
    // d3.csv('../example/nodes-data.csv').then(display);

    if (ppp) {
      // var popo = d3.csvParse(ppp);
      // console.log(popo);
      display(ppp);
    }
  };

  return (
    <div>
      <div id = 'vis'></div>
    </div>
  );
};
