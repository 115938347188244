import Grid from '@material-ui/core/Grid';

import HeaderDetail from '../../HeaderDetail'
import ReactTableExampleSubComponents from '../../Table/example/SubComponents/ReactTableExampleSubComponents'
import ReactTableExampleGlobalFilterEdit1 from '../../Table/example/GlobalFilterEdit1/ReactTableExampleGlobalFilterEdit1'
import ReactTableExampleGlobalFilterOrg   from '../../Table/example/GlobalFilterOrg/ReactTableExampleGlobalFilterOrg'

export default function Detail(props) {

  return (
    <div>
        ReactTableExample
        <Grid container>


          {/* 詳細ヘッダーの表示 */}
          <HeaderDetail />

          {/* グリッドのサンプル */}
          <Grid item xs={12}>
            <ReactTableExampleSubComponents></ReactTableExampleSubComponents>
          </Grid>
          <Grid item xs={12}>
            <ReactTableExampleGlobalFilterEdit1></ReactTableExampleGlobalFilterEdit1>
          </Grid>
          <Grid item xs={12}>
            <ReactTableExampleGlobalFilterOrg></ReactTableExampleGlobalFilterOrg>
          </Grid>
        </Grid>
    </div>
  );
};
