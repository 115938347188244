import { useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { CONSTANT } from '../Config';

export default function ApiList(props) {

  // ・urlを絶対パスにする必要があるｗｗ
  // 2022/04/03 コンポーネントにパスが付与されている場合、相対パスだと、サーバーへのリクエストパスに付与されてしまうため。。
  const url = CONSTANT.AWS_LAMBDA_DOMAIN + '/issue/list';
  // const url = '/issue/dynamodb/list';

  // useEffect:レンダー後の副作用
  // componentDidMount    Component が Mount された後に実行されるメソッド
  // componentDidUpdate   props または state が変更されたときに実行されます。
  // componentWillUnmount Component が Unmount されるときに実行されます。
  useEffect(() => {
    console.log('ApiList props');
    console.log(props);
    // 初回からリスト取得
    if (props.defaultGet) {
      refreshIssueList();
    }
  }, []); // end useEffect

  const refreshIssueList = () => {
    console.log('refreshIssueList');
    axios.get(url)
      .then(res => {
        const resData = res.data;
        console.log("resData");
        console.log(resData);
        const p = resData.map(data => {
          console.log(data.ID + " : " + data.SUBJECT);
        })
        // レスポンスをプロパティに設定
        props.setList(resData);

        // TODO ターゲットを設定
        const result = resData.filter(data => data.ID == 119);
        console.log("filter result ");
        console.log(result);
        if (result.length > 0) {
          props.setIssue(result[0]);
        }
    
      });
  };

  return (
    <div>
      <Button variant='outlined' color='secondary'
        onClick={() => refreshIssueList()}>リストの更新</Button>
    </div>
  );
};
